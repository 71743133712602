import { httpClient } from "./httpClient";
import { urlBase } from "./constants";

const getDayComments = async (token, day) => {
  const client = httpClient(token);
  let url = `${urlBase}/api/day_comments?day=${day.toISOString()}`;
  const { body } = await client.sendGet(url);
  return body;
};

const saveDayComments = async (token, data) => {
  const client = httpClient(token);
  const url = `${urlBase}/api/day_comments`;
  const { body } = await client.sendPost(url, data);
  return body;
};

const sendDayComments = async (token, day) => {
  const client = httpClient(token);
  const url = `${urlBase}/api/day_comments/${day}`;
  const { body } = await client.sendPost(url);
  return body;
};

export { getDayComments, saveDayComments, sendDayComments };
