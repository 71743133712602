import { urlBase } from "@/api/constants";
import axios from "axios";

export const register = async (data) => {
  try {
    const response = await axios.post(`${urlBase}/api/registration`, data);
    return { code: response.status, body: response.data };
  } catch (e) {
    if (e.response) return { code: e.response.status, body: e.response.data };
    return { code: 500, body: {} };
  }
};
