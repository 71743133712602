class HttpError extends Error {
  code;
  data;
  constructor(code, data) {
    super("Error");
    this.code = code;
    this.data = data;
  }
}

export { HttpError };
