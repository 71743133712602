import { createRouter, createWebHashHistory } from "vue-router";
import Anonymous from "../layouts/Anonymous.vue";
import Logged from "../layouts/Logged.vue";
import Planning from "../views/Planning.vue";
import Login from "../views/Login.vue";
import Classrooms from "../views/Classrooms.vue";
import Sessions from "../views/Sessions.vue";
import Employees from "../views/Employees.vue";
import Settings from "../views/Settings.vue";
import Profile from "../views/Profile.vue";
import PublicPlanning from "../views/PublicPlanning.vue";
import RequestResetPassword from "../views/RequestResetPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";
import store from "../store";
import Register from "@/views/Register";

const routes = [
  {
    path: "/",
    component: Logged,
    children: [
      { path: "/", name: "Planning", component: Planning },
      { path: "/classrooms", name: "Classrooms", component: Classrooms },
      { path: "/sessions", name: "Sessions", component: Sessions },
      { path: "/employees", name: "Employees", component: Employees },
      { path: "/settings", name: "Settings", component: Settings },
      { path: "/profile", name: "Profile", component: Profile },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: "/",
    component: Anonymous,
    children: [
      { path: "/register", name: "Register", component: Register },
      { path: "/login", name: "Login", component: Login },
      {
        path: "/request_reset_password",
        name: "RequestResetPassword",
        component: RequestResetPassword,
      },
      {
        path: "/reset_pass",
        name: "ResetPassword",
        component: ResetPassword,
      },
      {
        path: "/public_planning",
        name: "PublicPlanning",
        component: PublicPlanning,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    next();
    return;
  }

  if (store.state.token) {
    next();
    return;
  }

  next({ name: "Login", query: { redirect: to.fullPath } });
});

export default router;
