<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="card">
        <form @submit="submit" class="box">
          <div class="field">
            <div class="control">
              <div class="columns">
                <div class="column is-4-tablet">
                  <div class="label">Nombre</div>
                  <input
                    type="text"
                    v-model="local.firstName"
                    placeholder="Nombre"
                    class="input"
                  />
                </div>
                <div class="column is-8-tablet">
                  <div class="label">Apellidos</div>
                  <input
                    type="text"
                    v-model="local.lastName"
                    placeholder="Apellidos"
                    class="input"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <div class="columns">
                <div class="column is-4-tablet">
                  <div class="label">Teléfono</div>
                  <input
                    type="text"
                    v-model="local.phone"
                    placeholder="Teléfono"
                    class="input"
                  />
                </div>
                <div class="column is-8-tablet">
                  <div class="label">E-Mail</div>
                  <input
                    type="text"
                    v-model="local.email"
                    placeholder="E-Mail"
                    class="input"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="control mt-5 mb-5 has-text-centered">
              <label class="radio">
                <input type="radio" v-model="local.status" :value="1" />
                Activo
              </label>
              <label class="radio">
                <input type="radio" v-model="local.status" :value="2" />
                Anulado
              </label>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <FreeSessionsForm
                @update="freeSessionsChanged"
                :freeSessionsOfEmployee="employee?.freeSessions ?? []"
              />
            </div>
          </div>
          <FormButtons
            :submitAction="submit"
            :cancelAction="cancel"
            :loading="loading"
          />
        </form>
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="$emit('close')"
    ></button>
  </div>
</template>

<script>
import { ref, reactive } from "@vue/reactivity";
import FormButtons from "../FormButtons.vue";
import FreeSessionsForm from "./FreeSessionsForm.vue";
import { saveEmployee } from "../../api/employees";
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";

export default {
  components: {
    FormButtons,
    FreeSessionsForm,
  },
  props: {
    employee: Object,
  },
  emits: ["close"],
  setup(props, context) {
    const loading = ref(false);
    const store = useStore();
    const token = computed(() => store.state.token);
    const local = reactive(
      props.employee
        ? props.employee
        : {
            id: null,
            name: "",
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            status: 1,
            freeSessions: [],
          }
    );
    const freeSessionsChanged = (freeSessions) => {
      local.freeSessions = freeSessions.filter((f) => f.active);
    };
    const submit = async () => {
      loading.value = true;
      try {
        await saveEmployee(token.value, local);
        loading.value = false;
        context.emit("close");
      } catch (error) {
        loading.value = false;
        alert(error.data.error.message);
      }
    };
    const cancel = () => {
      context.emit("close");
    };

    const minutesSelect = () => {
      const range = [];
      for (let i = 0; i < 60; i += 5) {
        range.push(i);
      }
      return range;
    };

    return {
      loading,
      local,
      submit,
      cancel,
      minutesSelect,
      freeSessionsChanged,
    };
  },
};
</script>
