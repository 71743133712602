<template>
  <h1 class="title pt-3 pl-3 mb-2 has-text-centered is-4">
    Planning de sustituciones
  </h1>
  <div class="px-3 py-1 box">
    <Suspense>
      <template #default>
        <PublicWeekview v-if="$route.query.token" />
      </template>
      <template #fallback>
        <div>Cargando...</div>
      </template>
    </Suspense>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import PublicWeekview from "../components/PublicWeekview/PublicWeekview.vue";
export default {
  components: {
    PublicWeekview,
  },
  async setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    if (!route.query.token) {
      router.push({ name: "Login" });
      return;
    }
    store.commit("setToken", route.query.token);
  },
};
</script>
