<template>
  <div class="columns is-mobile is-centered mt-6">
    <div class="column is-12-mobile is-6-tablet is-4-widescreen">
      <form @submit="onSubmit" class="box">
        <div class="has-text-centered">
          <img src="img/logo.png" class="logo" alt="Gripau" />
        </div>
        <h1 class="title has-text-centered">Gripau</h1>
        <div class="field">
          <div class="control">
            <input
              type="text"
              v-model="organizationName"
              placeholder="Nombre del centro"
              class="input"
            />
          </div>
        </div>
        <div class="field">
          <div class="control">
            <input
              type="text"
              v-model="name"
              placeholder="Tu numbre"
              class="input"
            />
          </div>
        </div>
        <div class="field">
          <div class="control">
            <input
              type="text"
              v-model="username"
              placeholder="E-Mail"
              class="input"
            />
          </div>
        </div>
        <div class="field">
          <div class="control">
            <input
              type="password"
              v-model="password"
              placeholder="Contraseña"
              class="input"
            />
          </div>
        </div>
        <div class="field">
          <div class="control">
            <label class="checkbox">
              <input type="checkbox" v-model="accept" class="checkbox" />
              Acepto la
              <a href="https://google.es" target="_blank">
                política de privacidad
              </a>
            </label>
          </div>
        </div>
        <div class="field">
          <div class="control has-text-centered">
            <button
              @click="onSubmit"
              class="button is-primary"
              :class="{ 'is-loading': loading }"
            >
              Acceder
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { register } from "@/api/registrations";
import { login } from "@/api/users";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "Register",
  setup() {
    const loading = ref(false);
    const organizationName = ref("");
    const name = ref("");
    const username = ref("");
    const password = ref("");
    const accept = ref(false);
    const router = useRouter();
    const store = useStore();

    const onSubmit = async (event) => {
      event.preventDefault();

      if (accept.value !== true) {
        alert("Debes aceptar la política de privacidad");
        return;
      }

      loading.value = true;
      const { code: registrationCode } = await register({
        organizationName: organizationName.value,
        name: name.value,
        username: username.value,
        password: password.value,
        email: username.value,
      });
      if (registrationCode !== 201) {
        loading.value = false;
        alert("Error en el registro");
        return;
      }

      const { code: loginCode, body } = await login(
        username.value,
        password.value
      );
      if (loginCode !== 200) {
        loading.value = false;
        alert("Error");
        return;
      }
      localStorage.setItem("token", body.token);
      store.commit("setToken", body.token);
      loading.value = false;

      await router.push({ name: "Planning" });
      window.location.reload();
    };

    return {
      loading,
      organizationName,
      name,
      username,
      password,
      accept,
      onSubmit,
    };
  },
};
</script>
<style scoped>
img.logo {
  width: 150px;
  height: 150px;
}
</style>
