<template>
  <div class="has-text-centered">
    <div>
      <img
        :src="logoUrl"
        alt="Logo"
        style="max-width: 150px; max-height: 150px"
      />
    </div>
    <div class="file is-small is-centered">
      <label class="file-label">
        <input
          class="file-input"
          type="file"
          ref="file"
          name="logo"
          @change="upload"
        />
        <span class="file-cta">
          <span class="file-icon">
            <i class="fas fa-upload"></i>
          </span>
          <span class="file-label"> Cambiar logo</span>
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { urlBase } from "../../api/constants";
import { uploadLogo } from "../../api/organization";

export default {
  name: "OrganizationLogoForm",
  setup() {
    const store = useStore();
    const token = ref(store.state.token);
    const file = ref(null);
    const counter = ref(0);
    const logoUrl = ref(
      `${urlBase}/api/organizations/logo?token=${token.value}&t=${counter.value}`
    );

    const upload = async () => {
      const formData = new FormData();
      formData.append("logo", file.value.files[0]);
      await uploadLogo(token.value, formData);
      counter.value++;
      logoUrl.value = `${urlBase}/api/organizations/logo?token=${token.value}&t=${counter.value}`;
    };

    return {
      file,
      logoUrl,
      upload,
    };
  },
};
</script>

<style scoped></style>
