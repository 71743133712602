export const useDateFormat = () => {
  const dateFormat = (date) => {
    const parts = date.toISOString().split("-");
    const dayParts = parts[2].split("T");
    return `${dayParts[0]}/${parts[1]}/${parts[0]}`;
  };

  const formatTime = (hours, minutes) => {
    const formatedHours = hours > 9 ? `${hours}` : `0${hours}`;
    const formatedMinutes = minutes > 9 ? `${minutes}` : `0${minutes}`;
    return `${formatedHours}:${formatedMinutes}`;
  };

  return {
    dateFormat,
    formatTime,
  };
};
