<template>
  <div class="columns p-3">
    <div class="column is-3-mobile">
      <button
        class="button is-small pl-2 pr-2 is-light"
        @click="changeWeek(getPreviousMonday(days[0]))"
      >
        Anterior
      </button>
    </div>
    <div class="column has-text-centered has-text-weight-bold is-6-mobile pt-4">
      {{ dateFormat(days[0]) }} - {{ dateFormat(days[4]) }}
    </div>
    <div class="column is-3-mobile has-text-right">
      <button
        class="button is-small pl-2 pr-2 is-light"
        @click="changeWeek(getNextMonday(days[0]))"
      >
        Siguiente
      </button>
    </div>
  </div>
  <div class="table-container">
    <table class="table is-fullwidth is-bordered is-narrow">
      <thead>
        <tr>
          <th></th>
          <th>Lunes</th>
          <th>Martes</th>
          <th>Miércoles</th>
          <th>Jueves</th>
          <th>Viernes</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="session in sessions" :key="session.id">
          <th>
            {{ session.name }} -
            {{ formatTime(session.startHours, session.startMinutes) }} -
            {{ formatTime(session.endHours, session.endMinutes) }}
          </th>
          <SessionCell
            v-for="day in days"
            :key="day.toISOString()"
            :day="day"
            :session="session"
            :classrooms="classrooms"
            :replacements="replacements"
            @change="reloadReplacements"
          />
        </tr>
        <tr>
          <th>Observaciones</th>
          <th v-for="comment in commentsList" :key="comment.id">
            <textarea
              rows="7"
              v-model="comment.comments"
              @change="saveComment(comment)"
            />
            <div class="has-text-centered">
              <button
                  class="button is-light is-small"
                  :class="{'is-loading': sending === comment.day}"
                  @click="sendComments(comment.day)"
              >
                Enviar a todos
              </button>
            </div>
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useComments } from "../../hooks/useComments";
import { useWeekNavigation } from "../../hooks/useWeekNavigation";
import SessionCell from "./SessionCell.vue";
import { getClassrooms } from "../../api/classrooms";
import { getSessions } from "../../api/sessions";
import { getReplacements } from "../../api/replacements";
import { sendDayComments } from "../../api/comments";
import { useWeek } from "../../hooks/useWeek";
import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useDateFormat } from "../../hooks/useDateFormat";

export default {
  components: {
    SessionCell,
  },
  async setup() {
    const store = useStore();
    const token = computed(() => store.state.token);
    const { weekDays } = useWeek();
    const days = ref(weekDays(new Date(), 5));
    const classrooms = ref(await getClassrooms(token.value, 1));
    const sessions = ref(await getSessions(token.value, 1));
    const replacements = ref(
      await getReplacements(token.value, days.value[0], days.value[4])
    );

    const { formatTime, dateFormat } = useDateFormat();
    const { getCommentsList, saveComment } = useComments(
      days.value,
      token.value
    );
    const commentsList = ref(await getCommentsList(days.value));

    const reloadReplacements = async () => {
      replacements.value = await getReplacements(
        token.value,
        days.value[0],
        days.value[4]
      );
    };

    const { getPreviousMonday, getNextMonday } = useWeekNavigation();

    const changeWeek = async (date) => {
      commentsList.value = [];
      days.value = weekDays(new Date(date), 5);
      await reloadReplacements();
      commentsList.value = await getCommentsList(days.value);
    };

    const sleep = async (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    const sending = ref('');
    const sendComments = async(day) => {
      sending.value = day;
      await sleep(1000);
      await sendDayComments(token.value, day);
      sending.value = '';
    }

    return {
      classrooms,
      sessions,
      replacements,
      days,
      reloadReplacements,
      formatTime,
      dateFormat,
      saveComment,
      commentsList,
      changeWeek,
      getPreviousMonday,
      getNextMonday,
      sendComments,
      sending,
    };
  },
};
</script>

<style module>
th,
td {
  width: 12.5%;
}
textarea {
  width: 100%;
  padding: 5px !important;
}
</style>
