<template>
  <form @submit="send">
    <div class="field">
      <div class="control">
        <div class="label">Asunto</div>
        <input
          type="text"
          class="input"
          v-model="template.subject"
          @change="send"
        />
      </div>
    </div>
    <div class="field">
      <div class="control">
        <div class="label">Texto</div>
        <textarea class="textarea" v-model="template.body" @change="send" />
      </div>
    </div>
  </form>
</template>

<script>
import { reactive, ref } from "vue";
import { useStore } from "vuex";
import { getTemplate, updateTemplate } from "../../api/organization";

export default {
  name: "NotificationTemplateForm",
  props: {
    type: String,
  },
  async setup(props) {
    const store = useStore();
    const token = ref(store.state.token);

    const template = reactive(await getTemplate(token.value, props.type));
    const send = async () => {
      await updateTemplate(token.value, template);
    };

    return {
      template,
      send,
    };
  },
};
</script>

<style scoped></style>
