<template>
  <div class="field">
    <div class="control">
      <div class="columns">
        <div class="column is-3-desktop">
          <button
            v-if="deleteAction"
            type="button"
            class="button is-danger is-fullwidth"
            :class="{ 'is-loading': loadingDelete }"
            @click="deleteAction"
          >
            Eliminar
          </button>
        </div>
        <div class="column is-3-desktop is-offset-3-desktop is-hidden-mobile">
          <button
            v-if="cancelAction"
            type="button"
            class="button is-info is-fullwidth"
            @click="cancelAction"
          >
            Cancelar
          </button>
        </div>
        <div class="column is-3-desktop">
          <button
            v-if="submitAction"
            type="button"
            class="button is-primary is-fullwidth"
            :class="{ 'is-loading': loading }"
            @click="submitAction"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    loadingDelete: Boolean,
    deleteAction: Function,
    submitAction: Function,
    cancelAction: Function,
  },
};
</script>

<style></style>
