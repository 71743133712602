<template>
  <h1 class="title pt-4 pl-3">
    <span class="icon mr-2">
      <i class="fas fa-users has-text-grey-light"></i>
    </span>
    Empleados
  </h1>
  <div class="p-3 box">
    <div class="tabs is-boxed">
      <ul>
        <li :class="{ 'is-active': activeTab === 1 }">
          <a @click="setActiveTab(1)">Activos</a>
        </li>
        <li :class="{ 'is-active': activeTab === 2 }">
          <a @click="setActiveTab(2)">Anulados</a>
        </li>
      </ul>
    </div>
    <Suspense>
      <template #default>
        <EmployeesList :status="activeTab" />
      </template>
      <template #fallback>
        <div>Cargando...</div>
      </template>
    </Suspense>
  </div>
</template>
<script>
import { ref } from "@vue/reactivity";
import EmployeesList from "../components/Employee/EmployeesList.vue";

export default {
  components: {
    EmployeesList,
  },
  setup() {
    const activeTab = ref(1);
    const setActiveTab = (tabNumber) => {
      activeTab.value = tabNumber;
    };

    return {
      activeTab,
      setActiveTab,
    };
  },
};
</script>
