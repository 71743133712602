<template>
  <div class="columns is-mobile is-centered mt-6">
    <div class="column is-12-mobile is-6-tablet is-4-widescreen">
      <form @submit="send" class="box">
        <div class="has-text-centered">
          <img src="img/logo.png" class="logo" alt="Gripau" />
        </div>
        <h1 class="title has-text-centered">Gripau</h1>
        <h2 class="title has-text-centered is-5 mb-5">
          Restablecer contraseña
        </h2>
        <p
          v-if="done"
          class="has-background-success-light has-text-centered my-5 p-3"
        >
          Se ha cambiado la contraseña.
        </p>
        <div v-if="done" class="has-text-centered mt-5">
          <router-link :to="{ name: 'Login' }" class="button is-primary">
            Acceder
          </router-link>
        </div>
        <div class="field mt-5" v-if="!done">
          <div class="control">
            <div class="label">Nueva contraseña</div>
            <input type="password" v-model="password" class="input" />
          </div>
        </div>
        <div class="field mt-5" v-if="!done">
          <div class="control">
            <div class="label">Repita la contraseña</div>
            <input type="password" v-model="password2" class="input" />
          </div>
        </div>
        <div class="field" v-if="!done">
          <div class="control has-text-centered">
            <button
              class="button is-primary"
              :class="{ 'is-loading': loading }"
              :disabled="loading"
            >
              Enviar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { resetPassword } from "../api/users";

export default {
  setup() {
    const route = useRoute();
    const token = route.query.token;
    const password = ref("");
    const password2 = ref("");
    const loading = ref(false);
    const done = ref(false);

    const send = async (e) => {
      e.preventDefault();
      if (password.value === "") {
        alert("Especifica una contraseña");
        return;
      }
      if (password.value !== password2.value) {
        alert("Las contraseñas no coinciden");
        return;
      }
      if (password.value.length < 6) {
        alert("La contraseña debe tener al menos 6 caracteres de longitud.");
        return;
      }

      try {
        loading.value = true;
        await resetPassword(password.value, token);
        done.value = true;
        loading.value = false;
      } catch (e) {
        loading.value = false;
        alert("Error inesperado");
      }
    };

    return { password, password2, loading, done, send };
  },
};
</script>
<style scoped>
img.logo {
  width: 150px;
  height: 150px;
}
</style>
