import { urlBase } from "./constants";
import { httpClient } from "./httpClient";

export const updateOrganization = async (token, data) => {
  const client = httpClient(token);
  let url = `${urlBase}/api/organizations`;
  const { body } = await client.sendPut(url, data);
  return body;
};

export const getOrganization = async (token) => {
  const client = httpClient(token);
  let url = `${urlBase}/api/organizations`;
  const { body } = await client.sendGet(url);
  return body;
};

export const uploadLogo = async (token, formData) => {
  const client = httpClient(token, { "Content-Type": "multipart/form-data" });
  let url = `${urlBase}/api/organizations/logo`;
  const { body } = await client.sendPost(url, formData);
  return body;
};

export const getTemplate = async (token, type) => {
  const client = httpClient(token, { "Content-Type": "multipart/form-data" });
  let url = `${urlBase}/api/notifications/template/${type}`;
  const { body } = await client.sendGet(url);
  return body;
};

export const updateTemplate = async (token, data) => {
  const client = httpClient(token, { "Content-Type": "multipart/form-data" });
  let url = `${urlBase}/api/notifications/template/${data.type}`;
  const { body } = await client.sendPut(url, data);
  return body;
};
