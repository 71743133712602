<template>
  <nabvar />
  <div class="container">
    <router-view></router-view>
  </div>
</template>

<script>
import Nabvar from "../components/Nabvar.vue";

export default {
  components: {
    Nabvar,
  },
};
</script>
