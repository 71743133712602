<template>
  <form @submit="send">
    <div class="field">
      <div class="control">
        <div class="label">Nombre</div>
        <input
          type="text"
          v-model="user.name"
          placeholder="Nombre"
          class="input"
        />
      </div>
    </div>
    <div class="field">
      <div class="control">
        <div class="label">E-Mail</div>
        <input
          type="text"
          v-model="user.email"
          placeholder="E-Mail"
          class="input"
        />
      </div>
    </div>
    <div class="field">
      <div class="control">
        <div class="label">Firma</div>
        <textarea v-model="user.emailSignature" rows="5" class="textarea" />
      </div>
    </div>
    <div class="field">
      <div class="field mt-4">
        <div class="control has-text-right">
          <button class="button is-primary" :class="{ 'is-loading': loading }">
            Guardar
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { reactive, ref } from "vue";
import { useStore } from "vuex";
import { getUser, updateUser } from "../../api/users";

export default {
  async setup() {
    const store = useStore();
    const token = computed(() => store.state.token);
    const user = reactive(await getUser(token.value));
    const loading = ref(false);

    const send = async (event) => {
      event.preventDefault();
      loading.value = true;
      await updateUser(token.value, user);
      loading.value = false;
    };

    return { user, loading, send };
  },
};
</script>
<style scoped></style>
