<template>
  <p class="has-text-centered">
    <button class="button is-light is-small" @click="copy()">
      Copiar enlace público
    </button>
  </p>
</template>
<script>
import { ref } from "vue";
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";
import { getPublicToken } from "../../api/users";

export default {
  async setup() {
    const store = useStore();
    const token = computed(() => store.state.token);
    const response = await getPublicToken(token.value);
    const publicToken = ref(response.token);

    const copy = () => {
      const element = document.createElement("input");
      element.value =
        window.location.href + "public_planning?token=" + publicToken.value;
      element.setAttribute("type", "text");
      document.body.append(element);
      element.select();
      document.execCommand("copy");
      element.remove();
      alert("Enlace copiado al portapapeles");
    };

    return {
      publicToken,
      copy,
    };
  },
};
</script>
