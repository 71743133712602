<template>
  <Suspense>
    <template #default>
      <router-view></router-view>
    </template>
  </Suspense>
</template>

<script>
export default {};
</script>
