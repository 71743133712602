<template>
  <h1 class="title pt-4 pl-3">
    <span class="icon mr-2">
      <i class="fas fa-cog has-text-grey-light"></i>
    </span>
    Configuración
  </h1>
  <div class="box">
    <div class="tabs is-boxed">
      <ul>
        <li :class="{ 'is-active': active === 1 }">
          <a @click="active = 1">Centro</a>
        </li>
        <li :class="{ 'is-active': active === 2 }">
          <a @click="active = 2">Notificaciones</a>
        </li>
      </ul>
    </div>
    <div v-if="active === 1">
      <h2 class="title is-4 mt-5 mb-3 has-text-grey">
        Configuración del centro
      </h2>
      <organization-logo-form />
      <Suspense>
        <template #default>
          <organiztion-form />
        </template>
      </Suspense>
    </div>
    <div v-if="active === 2">
      <div class="box has-background-light">
        <p>
          Mediante estas plantillas puedes personalizar las notificaciones que
          se envían por e-mail a los empleados.
        </p>
        <p>
          El sistema cuenta con un sistema de etiquetas que se sustituyen por el
          valor concreto en el momento de la notificación. Las tiquetas van
          encerradas entre dobles llaves y las disponibles son:
        </p>
        <table class="table is-fullwidth is-narrow mt-4 has-background-light">
          <tbody>
            <tr>
              <th><strong>date</strong></th>
              <td>Fecha de la sustitucion.</td>
            </tr>
            <tr>
              <th><strong>firstName</strong></th>
              <td>Nombre de pila del empleado a notificar.</td>
            </tr>
            <tr>
              <th><strong>completeName</strong></th>
              <td>Nombre y apellidos del empleado a notificar.</td>
            </tr>
            <tr>
              <th><strong>start</strong></th>
              <td>Hora de comienzo de la sustitución.</td>
            </tr>
            <tr>
              <th><strong>end</strong></th>
              <td>Hora de finalización de la sustitución.</td>
            </tr>
            <tr>
              <th><strong>oldClassroom</strong></th>
              <td>Nombre del aula anterior.</td>
            </tr>
            <tr>
              <th><strong>classroom</strong></th>
              <td>Nombre del aula.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h2 class="title is-4 mt-5 mb-3 has-text-grey">
        Plantilla de notificación para sustituciones asignadas.
      </h2>
      <Suspense>
        <template #default>
          <notification-template-form type="assign" />
        </template>
      </Suspense>

      <h2 class="title is-4 mt-5 mb-3 has-text-grey">
        Plantilla de notificación para sustituciones canceladas.
      </h2>
      <Suspense>
        <template #default>
          <notification-template-form type="cancel" />
        </template>
      </Suspense>

      <h2 class="title is-4 mt-5 mb-3 has-text-grey">
        Plantilla de notificación para sustituciones con cambios de aula.
      </h2>
      <Suspense>
        <template #default>
          <notification-template-form type="classroom_changed" />
        </template>
      </Suspense>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import NotificationTemplateForm from "../components/Settings/NotificationTemplateForm";
import OrganizationLogoForm from "../components/Settings/OrganizationLogoForm";
import OrganiztionForm from "../components/Settings/OrganiztionForm";

export default {
  components: {
    NotificationTemplateForm,
    OrganizationLogoForm,
    OrganiztionForm,
  },
  setup() {
    const active = ref(1);
    return {
      active,
    };
  },
};
</script>
<style scoped>
td {
  width: 100px;
}
</style>
