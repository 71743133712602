<template>
  <td class="pl-1 pr-1">
    <div
      class="free button is-mini is-success is-light"
      @click="openModal(day, session, null)"
    >
      + Añadir
    </div>
    <template
      v-for="repl in findSessionReplacements(day, session)"
      :key="repl.id"
    >
      <button
        class="button is-mini"
        :class="{ 'is-danger': !repl.employee, 'is-success': repl.employee }"
        @click="openModal(day, repl.session, repl.classroom)"
      >
        <template v-if="repl.employee">
          Aula {{ repl.classroom.name }}<br />
          {{ repl?.employee?.firstName ?? "" }}
          {{ repl?.employee?.lastName ?? "" }}
        </template>
        <template v-else> Aula {{ repl.classroom.name }} </template>
      </button>
    </template>

    <ReplacementForm
      :replacement="selectedReplacement"
      :modalOpen="modalOpen"
      @close="closeModal"
    />
  </td>
</template>

<script>
import ReplacementForm from "../Replacement/ReplacementForm.vue";
import { reactive, ref } from "vue";
import { useFindReplacement } from "../../hooks/useFindReplacement";

export default {
  components: {
    ReplacementForm,
  },
  props: {
    day: Date,
    session: Object,
    classrooms: Array,
    replacements: Array,
  },
  emits: ["change"],
  setup(props, context) {
    const { findReplacement, findSessionReplacements } =
      useFindReplacement(props);
    const modalOpen = ref(false);
    const selectedReplacement = reactive({
      id: null,
      date: null,
      session: null,
      classroom: null,
      employee: null,
      comments: null,
    });

    const openModal = (day, session, classroom) => {
      selectedReplacement.id = null;
      selectedReplacement.date = day;
      selectedReplacement.session = session;
      selectedReplacement.classroom = null;
      selectedReplacement.employee = null;
      selectedReplacement.comments = null;
      const replacement = findReplacement(day, session, classroom);
      if (replacement) {
        selectedReplacement.id = replacement.id;
        selectedReplacement.employee = replacement.employee;
        selectedReplacement.classroom = replacement.classroom;
        selectedReplacement.comments = replacement.comments;
      }
      modalOpen.value = true;
    };

    const closeModal = () => {
      modalOpen.value = false;
      context.emit("change");
    };

    return {
      findReplacement,
      modalOpen,
      openModal,
      closeModal,
      selectedReplacement,
      findSessionReplacements,
    };
  },
};
</script>

<style scoped>
.button {
  width: 100%;
}
.button.is-mini {
  padding: 0;
  height: auto;
  margin-bottom: 1px;
  margin-top: 1px;
  font-size: 0.9rem;
}
</style>
