import { urlBase } from "./constants";
import { httpClient } from "./httpClient";

const getReplacements = async (token, start, end) => {
  const client = httpClient(token);
  let url = `${urlBase}/api/replacements?`;
  if (start) {
    url += `start=${start.toISOString().split("T")[0]}&`;
  }
  if (end) {
    url += `end=${end.toISOString().split("T")[0]}&`;
  }
  const { body } = await client.sendGet(url);
  return body;
};

const updateReplacement = async (token, data) => {
  const client = httpClient(token);
  const url = `${urlBase}/api/replacements/${data.id}`;
  const { body } = await client.sendPut(url, data);
  return body;
};

const createReplacement = async (token, data) => {
  const client = httpClient(token);
  const url = `${urlBase}/api/replacements`;
  const { body } = await client.sendPost(url, data);
  return body;
};

const saveReplacement = async (token, data) => {
  if (data.id) {
    return updateReplacement(token, data);
  }
  return createReplacement(token, data);
};

const deleteReplacement = async (token, id) => {
  const client = httpClient(token);
  const url = `${urlBase}/api/replacements/${id}`;
  const { body } = await client.sendDelete(url);
  return body;
};

const getReplacementNotifications = async (token, id) => {
  const client = httpClient(token);
  const url = `${urlBase}/api/replacements/${id}/notifications`;
  const { body } = await client.sendGet(url);
  return body;
};

export {
  getReplacements,
  saveReplacement,
  deleteReplacement,
  getReplacementNotifications,
};
