export const useWeek = () => {
  const getIsoDayOfWeek = (date) =>
    date.getDay() === 0 ? 6 : date.getDay() - 1;

  const calcFirstDay = (date) => date.getDate() - getIsoDayOfWeek(date);

  const calcLastDay = (date) => calcFirstDay(date) + 6;

  const calcDateOfDay = (date, day) => {
    const numberDay = calcFirstDay(date) + day;
    return new Date(date.setDate(numberDay));
  };

  const weekDays = (date, numberOfDays = 7) => {
    const days = [];
    for (let i = 0; i < numberOfDays; i++) {
      days.push(calcDateOfDay(date, i));
    }
    return days;
  };

  const lastMonday = (date = new Date()) => {
    const firstDay = calcFirstDay(date);
    const monday = new Date(date.setDate(firstDay));
    return new Date(monday.toISOString().split("T")[0]);
  };

  const nextSunday = (date = new Date()) => {
    const lastDay = calcLastDay(date);
    const sunday = new Date(date.setDate(lastDay));
    return new Date(sunday.toISOString().split("T")[0]);
  };

  return {
    lastMonday,
    nextSunday,
    weekDays,
  };
};
