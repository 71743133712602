import axios from "axios";
import { HttpError } from "./error";

const httpClient = (token, headers = {}) => {
  const request = async (url, method, data = null) => {
    try {
      const response = await axios.request({
        method,
        data,
        url,
        headers: { ...headers, authorization: `Bearer ${token}` },
      });

      return {
        code: response.status,
        body: response.data,
      };
    } catch (e) {
      if (e.response.status === 401) {
        window.location.hash = "login";
      }
      throw new HttpError(e.response.status, e.response.data);
    }
  };

  const sendGet = async (url) => await request(url, "GET");
  const sendPost = async (url, data) => await request(url, "POST", data);
  const sendPut = async (url, data) => await request(url, "PUT", data);
  const sendDelete = async (url) => await request(url, "DELETE");

  return { sendGet, sendPost, sendPut, sendDelete };
};

export { httpClient };
