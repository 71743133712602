export const useFindReplacement = (props) => {
  const sameDay = (dateString, current) =>
    dateString.split("T")[0] === current.toISOString().split("T")[0];
  const sameSession = (session, current) => session.id === current.id;
  const sameClassroom = (classroom, current) => classroom?.id === current?.id;

  const findReplacement = (currentDay, currentSession, currentClassroom) => {
    return props.replacements.find(
      ({ date: dateString, session, classroom }) =>
        sameDay(dateString, currentDay) &&
        sameSession(session, currentSession) &&
        sameClassroom(classroom, currentClassroom)
    );
  };

  const findSessionReplacements = (currentDay, currentSession) => {
    return props.replacements.filter(
      ({ date: dateString, session }) =>
        sameDay(dateString, currentDay) && sameSession(session, currentSession)
    );
  };

  return { findReplacement, findSessionReplacements };
};
