<template>
  <h1 class="title pt-4 pl-3">
    <span class="icon mr-2">
      <i class="fas fa-user has-text-grey-light"></i>
    </span>
    Mi perfíl
  </h1>
  <div class="py-5 box">
    <div class="columns">
      <div class="column is-offset-2 is-8 pb-5">
        <Suspense>
          <template #default>
            <UserForm />
          </template>
        </Suspense>
      </div>
    </div>
  </div>
</template>
<script>
import UserForm from "../components/User/UserForm";
export default {
  components: { UserForm },
};
</script>
