import { getDayComments, saveDayComments } from "../api/comments";

export const useComments = (days, token) => {
  const getCommentsList = async (days) => {
    const commentsList = [];
    for (const day of days) {
      commentsList.push(await getDayComments(token, day));
    }
    return commentsList;
  };

  const saveComment = async (comments) => {
    await saveDayComments(token, comments);
  };

  return {
    saveComment,
    getCommentsList,
  };
};
