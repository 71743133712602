<template>
  <div class="has-text-centered pb-3">
    <button
      type="button"
      class="button is-info is-small"
      @click="openForm(null)"
    >
      Agregar sesión
    </button>
  </div>
  <table class="table is-fullwidth">
    <thead>
      <th class="time">Comienzo</th>
      <th class="time">Final</th>
      <th>Sesión</th>
      <th></th>
    </thead>
    <tbody>
      <tr v-for="item in items" :key="item.id">
        <td class="time">
          {{ formatTime(item.startHours, item.startMinutes) }}
        </td>
        <td class="time">
          {{ formatTime(item.endHours, item.endMinutes) }}
        </td>
        <td>{{ item.name }}</td>
        <td class="has-text-right">
          <button
            type="button"
            class="button is-primary is-small"
            @click="openForm(item)"
          >
            Editar
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <SessionForm
    v-if="modalOpen"
    :session="selected"
    @close="updateListAndClose"
  />
</template>

<script>
import { ref } from "@vue/reactivity";
import { getSessions } from "../../api/sessions";
import { useStore } from "vuex";
import { computed, watch } from "@vue/runtime-core";
import SessionForm from "./SessionForm.vue";
import { toRefs } from "vue";

export default {
  components: { SessionForm },
  props: {
    status: Number,
  },
  async setup(props) {
    const store = useStore();
    const token = computed(() => store.state.token);
    const { status } = toRefs(props);
    const items = ref(await getSessions(token.value, status.value));
    const selected = ref(null);
    const modalOpen = ref(false);
    const updateList = async () => {
      items.value = await getSessions(token.value, status.value);
    };
    const updateListAndClose = async () => {
      await updateList();
      modalOpen.value = false;
    };
    watch(status, updateList);

    const formatTime = (hours, minutes) => {
      const formatedHours = hours > 9 ? `${hours}` : `0${hours}`;
      const formatedMinutes = minutes > 9 ? `${minutes}` : `0${minutes}`;
      return `${formatedHours}:${formatedMinutes}`;
    };

    const openForm = (classroom) => {
      selected.value = classroom;
      modalOpen.value = true;
    };

    return {
      items,
      modalOpen,
      openForm,
      selected,
      updateListAndClose,
      formatTime,
    };
  },
};
</script>

<style>
th.time,
td.time {
  width: 2%;
}
</style>
