<template>
  <form @submit="send">
    <div class="field">
      <div class="control">
        <div class="label">Nombre del centro</div>
        <input
          type="text"
          v-model="organization.name"
          placeholder="Nombre"
          class="input"
          @change="send"
        />
      </div>
    </div>
  </form>
</template>

<script>
import { reactive, ref } from "vue";
import { useStore } from "vuex";
import { getOrganization, updateOrganization } from "../../api/organization";

export default {
  name: "OrganiztionForm",
  async setup() {
    const store = useStore();
    const token = ref(store.state.token);
    const organization = reactive(await getOrganization(token.value));

    const send = async () => {
      await updateOrganization(token.value, organization);
    };

    return {
      organization,
      send,
    };
  },
};
</script>

<style scoped></style>
