<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="card">
        <form @submit="submit" class="box">
          <div class="field">
            <div class="control">
              <div class="label">Nombre</div>
              <input
                type="text"
                v-model="local.name"
                placeholder="Nombre"
                class="input"
              />
            </div>
          </div>
          <div class="field">
            <div class="control">
              <div class="columns">
                <div class="column is-6-mobile">
                  <div class="label">Comienzo</div>
                  <div class="columns is-gapless">
                    <div class="column is-6-mobile">
                      <div class="select is-fullwidth pr-1">
                        <select v-model="local.startHours">
                          <option
                            v-for="hour in 24"
                            :key="'hour_start_' + hour"
                            :value="hour - 1"
                          >
                            {{ hour - 1 }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="column is-6-mobile">
                      <div class="select is-fullwidth pl-1">
                        <select v-model="local.startMinutes">
                          <option
                            v-for="minute in minutesSelect()"
                            :key="'minute_start_' + minute"
                            :value="minute"
                          >
                            {{ minute }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column is-6-mobile">
                  <div class="label">Final</div>
                  <div class="columns is-gapless">
                    <div class="column is-6-mobile">
                      <div class="select is-fullwidth pr-1">
                        <select v-model="local.endHours">
                          <option
                            v-for="hour in 24"
                            :key="'hour_start_' + hour"
                            :value="hour - 1"
                          >
                            {{ hour - 1 }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="column is-6-mobile">
                      <div class="select is-fullwidth pl-1">
                        <select v-model="local.endMinutes">
                          <option
                            v-for="minute in minutesSelect()"
                            :key="'minute_start_' + minute"
                            :value="minute"
                          >
                            {{ minute }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="control mt-5 mb-5 has-text-centered">
              <label class="radio">
                <input type="radio" v-model="local.status" :value="1" />
                Activo
              </label>
              <label class="radio">
                <input type="radio" v-model="local.status" :value="2" />
                Anulado
              </label>
            </div>
          </div>
          <FormButtons
            :submitAction="submit"
            :cancelAction="cancel"
            :loading="loading"
          />
        </form>
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="$emit('close')"
    ></button>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { ref } from "vue";
import FormButtons from "../FormButtons.vue";
import { saveSession } from "../../api/sessions";
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";

export default {
  components: {
    FormButtons,
  },
  props: {
    session: Object,
  },
  emits: ["close"],
  setup(props, context) {
    const loading = ref(false);
    const store = useStore();
    const token = computed(() => store.state.token);
    const local = reactive(
      props.session
        ? props.session
        : {
            id: null,
            name: "",
            status: 1,
            startHours: null,
            startMinutes: null,
            endHours: null,
            endMinutes: null,
          }
    );
    const submit = async () => {
      loading.value = true;
      try {
        await saveSession(token.value, local);
        loading.value = false;
        context.emit("close");
      } catch (error) {
        loading.value = false;
        alert(error.data.error.message);
      }
    };
    const cancel = () => {
      context.emit("close");
    };

    const minutesSelect = () => {
      const range = [];
      for (let i = 0; i < 60; i += 5) {
        range.push(i);
      }
      return range;
    };

    return {
      loading,
      local,
      submit,
      cancel,
      minutesSelect,
    };
  },
};
</script>
