<template>
  <p class="mt-5 mb-2 has-text-centered">
    <strong class="has-text-dark">
      Marca las sesiones libres de este empleado.
    </strong>
  </p>
  <table class="table is-fullwidth is-narrow">
    <thead>
      <tr>
        <th class="is-size-7"></th>
        <th class="is-size-7">Lun</th>
        <th class="is-size-7">Mar</th>
        <th class="is-size-7">Mie</th>
        <th class="is-size-7">Jue</th>
        <th class="is-size-7">Vie</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="session in sessions" :key="session.id">
        <th class="is-size-7">
          {{ formatTime(session.startHours, session.startMinutes) }} -
          {{ formatTime(session.endHours, session.endMinutes) }}
        </th>
        <td v-for="day in 5" :key="'day' + day">
          <button
            class="button is-mini is-fullwidth"
            :class="{
              'is-success': getFreeSession(session.id, day),
              'is-danger': !getFreeSession(session.id, day),
            }"
            @click="toggleFreeSession(session.id, day, $event)"
          >
            &nbsp;{{ day }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { computed, ref, useContext } from "@vue/runtime-core";
import { useStore } from "vuex";
import { getSessions } from "../../api/sessions";
import { useDateFormat } from "../../hooks/useDateFormat";

export default {
  emits: ["update"],
  props: {
    freeSessionsOfEmployee: Array,
  },
  async setup(props) {
    const store = useStore();
    const context = useContext();
    const token = computed(() => store.state.token);
    const response = await getSessions(token.value, 1);
    const sessions = ref(response);
    const freeSessions = ref([]);
    for (let day = 1; day <= 5; day++) {
      sessions.value.forEach((s) => {
        freeSessions.value.push({
          dayOfWeek: day,
          session: s.id,
          active: props.freeSessionsOfEmployee.some(
            (f) => f.session === s.id && f.dayOfWeek === day
          ),
        });
      });
    }
    const { formatTime } = useDateFormat();

    const getFreeSession = (sessionId, dayOfWeek) => {
      return freeSessions.value.some(
        (x) =>
          x.session === sessionId &&
          x.dayOfWeek === dayOfWeek &&
          x.active === true
      );
    };
    const toggleFreeSession = (sessionId, dayOfWeek, event) => {
      event.preventDefault();
      const index = freeSessions.value.findIndex(
        (x) => x.session === sessionId && x.dayOfWeek === dayOfWeek
      );
      freeSessions.value[index].active = !freeSessions.value[index].active;
      context.emit("update", freeSessions.value);
    };

    return {
      sessions,
      formatTime,
      freeSessions,
      getFreeSession,
      toggleFreeSession,
    };
  },
};
</script>

<style></style>
