<template>
  <nav class="navbar is-primary" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand">
        <a class="navbar-item mr-4 ml-3" href="./#/">
          <img src="img/logo-brand.png" alt="Gripau logo" class="pr-2" />
          <strong>Gripau</strong>
        </a>
        <a
          role="button"
          class="navbar-burger"
          :class="{ 'is-active': burgerActive }"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          @click="burgerActive = !burgerActive"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        id="navbarBasicExample"
        class="navbar-menu"
        :class="{ 'is-active': burgerActive }"
      >
        <div class="navbar-start">
          <router-link
            :to="{ name: 'Planning' }"
            class="navbar-item"
            @click="burgerActive = false"
          >
            <span class="icon is-small mr-2">
              <i class="fas fa-calendar"></i>
            </span>
            <span>Planning</span>
          </router-link>
          <router-link
            :to="{ name: 'Classrooms' }"
            class="navbar-item"
            @click="burgerActive = false"
          >
            <span class="icon is-small mr-2">
              <i class="fas fa-building"></i>
            </span>
            <span>Aulas</span>
          </router-link>
          <router-link
            :to="{ name: 'Sessions' }"
            class="navbar-item"
            @click="burgerActive = false"
          >
            <span class="icon is-small mr-2">
              <i class="fas fa-clock"></i>
            </span>
            <span>Sesiones</span>
          </router-link>
          <router-link
            :to="{ name: 'Employees' }"
            class="navbar-item"
            @click="burgerActive = false"
          >
            <span class="icon is-small mr-2">
              <i class="fas fa-users"></i>
            </span>
            <span>Empleados</span>
          </router-link>
          <router-link
            :to="{ name: 'Settings' }"
            class="navbar-item is-hidden-desktop"
            @click="
              dropdownActive = false;
              burgerActive = false;
            "
          >
            <span class="icon is-small mr-2">
              <i class="fas fa-cog"></i>
            </span>
            <span>Configuración</span>
          </router-link>
          <router-link
            :to="{ name: 'Login' }"
            class="navbar-item is-hidden-desktop"
            @click="
              dropdownActive = false;
              burgerActive = false;
            "
          >
            <span class="icon is-small mr-2">
              <i class="fas fa-sign-out-alt"></i>
            </span>
            <span>Desconectar</span>
          </router-link>
        </div>

        <div class="navbar-end pr-5 is-hidden-mobile">
          <div
            class="dropdown is-right"
            :class="{ 'is-active': dropdownActive }"
          >
            <div
              class="dropdown-trigger"
              @click="dropdownActive = !dropdownActive"
            >
              <button
                class="button avatar has-text-centered"
                aria-haspopup="true"
                aria-controls="dropdown-avatar"
              >
                <span class="icon is-small">
                  <i class="fas fa-user"></i>
                </span>
              </button>
            </div>
            <div class="dropdown-menu" id="dropdown-avatar" role="menu">
              <div class="dropdown-content">
                <router-link
                  :to="{ name: 'Profile' }"
                  class="dropdown-item"
                  @click="dropdownActive = false"
                >
                  <span class="icon is-small mr-3">
                    <i class="fas fa-user"></i>
                  </span>
                  <span>Mi perfíl</span>
                </router-link>
                <router-link
                  :to="{ name: 'Settings' }"
                  class="dropdown-item"
                  @click="dropdownActive = false"
                >
                  <span class="icon is-small mr-3">
                    <i class="fas fa-cog"></i>
                  </span>
                  <span>Configuración</span>
                </router-link>
                <router-link
                  :to="{ name: 'Login' }"
                  class="dropdown-item"
                  @click="
                    removeToken();
                    dropdownActive = false;
                  "
                >
                  <span class="icon is-small mr-3">
                    <i class="fas fa-sign-out-alt"></i>
                  </span>
                  <span>Desconectar</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";

export default {
  setup() {
    const dropdownActive = ref(false);
    const burgerActive = ref(false);

    const store = useStore();
    const removeToken = () => {
      store.commit("setToken", "");
      localStorage.removeItem("token");
    };

    return {
      dropdownActive,
      burgerActive,
      removeToken,
    };
  },
};
</script>
<style scoped>
.avatar {
  height: 36px;
  width: 36px;
  border-radius: 18px;
  margin-top: 8px;
  border: 2px solid #eee !important;
  background: transparent;
  color: #eee !important;
}
.navbar-start .icon.is-small {
  font-size: 0.9rem;
}
</style>
