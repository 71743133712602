import { httpClient } from "./httpClient";
import { urlBase } from "./constants";

const getSessions = async (token, status = null) => {
  const client = httpClient(token);
  let url = `${urlBase}/api/sessions`;
  if (status) {
    url += `?status=${status}`;
  }
  const { body } = await client.sendGet(url);
  return body;
};

const createSession = async (token, data) => {
  const client = httpClient(token);
  const url = `${urlBase}/api/sessions`;
  const { body } = await client.sendPost(url, data);
  return body;
};

const updateSession = async (token, data) => {
  const client = httpClient(token);
  const url = `${urlBase}/api/sessions/${data.id}`;
  const { body } = await client.sendPut(url, data);
  return body;
};

const saveSession = async (token, data) => {
  if (data.id) {
    return updateSession(token, data);
  }
  return createSession(token, data);
};

export { getSessions, saveSession };
