<template>
  <div class="columns is-mobile is-centered mt-6">
    <div class="column is-12-mobile is-6-tablet is-4-widescreen">
      <form @submit="onSubmit" class="box">
        <div class="has-text-centered">
          <img src="img/logo.png" class="logo" alt="Gripau" />
        </div>
        <h1 class="title has-text-centered">Gripau</h1>
        <div class="field">
          <div class="control">
            <input
              type="text"
              v-model="username"
              placeholder="E-Mail"
              class="input"
            />
          </div>
        </div>
        <div class="field">
          <div class="control">
            <input
              type="password"
              v-model="password"
              placeholder="Contraseña"
              class="input"
            />
          </div>
        </div>
        <div class="field">
          <div class="control has-text-centered">
            <button
              @click="onSubmit"
              class="button is-primary"
              :class="{ 'is-loading': loading }"
            >
              Acceder
            </button>
          </div>
        </div>
      </form>
      <div class="has-text-centered">
        <router-link :to="{ name: 'RequestResetPassword' }">
          He olvidado mi contraseña.
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { login } from "../api/users";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  components: {},
  setup() {
    const loading = ref(false);
    const username = ref("");
    const password = ref("");
    const store = useStore();
    const router = useRouter();

    if (store.state.token) {
      router.push("/");
    }

    const onSubmit = async (event) => {
      loading.value = true;
      event.preventDefault();
      const { code, body } = await login(username.value, password.value);
      loading.value = false;
      if (code !== 200) {
        alert("Error");
        return;
      }
      localStorage.setItem("token", body.token);
      store.commit("setToken", body.token);
      router.push({ name: "Planning" });
    };

    return { loading, username, password, onSubmit };
  },
};
</script>

<style scoped>
img.logo {
  width: 150px;
  height: 150px;
}
</style>
