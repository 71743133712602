<template>
  <td class="pl-1 pr-1">
    <template
      v-for="repl in findSessionReplacements(day, session)"
      :key="repl.id"
    >
      <button
        v-if="repl.employee"
        class="button is-mini"
        :class="{ 'is-danger': !repl.employee, 'is-success': repl.employee }"
      >
        Aula {{ repl.classroom.name }}<br />
        {{ repl?.employee?.firstName ?? "" }}
        {{ repl?.employee?.lastName ?? "" }}
      </button>
    </template>
  </td>
</template>

<script>
import { useFindReplacement } from "../../hooks/useFindReplacement";

export default {
  props: {
    day: Date,
    session: Object,
    classrooms: Array,
    replacements: Array,
  },
  emits: ["change"],
  setup(props) {
    const { findSessionReplacements } = useFindReplacement(props);
    return {
      findSessionReplacements,
    };
  },
};
</script>

<style scoped>
.button {
  width: 100%;
}
.button.is-mini {
  padding: 0;
  height: auto;
  margin-bottom: 1px;
  margin-top: 1px;
  line-height: 22px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 1.5rem !important;
}
</style>
