<template>
  <div class="has-text-centered">
    <img :src="logoUrl" width="100" alt="logo" />
  </div>
  <div class="columns px-3">
    <div class="column is-3-mobile">
      <button
        class="button is-small pl-2 pr-2 is-light"
        @click="changeWeek(getPreviousMonday(days[0]))"
      >
        Anterior
      </button>
    </div>
    <div class="column has-text-centered has-text-weight-bold is-6-mobile pt-4">
      {{ dateFormat(days[0]) }} - {{ dateFormat(days[4]) }}
    </div>
    <div class="column is-3-mobile has-text-right">
      <button
        class="button is-small pl-2 pr-2 is-light"
        @click="changeWeek(getNextMonday(days[0]))"
      >
        Siguiente
      </button>
    </div>
  </div>
  <div class="table-container">
    <table class="table is-fullwidth is-bordered is-narrow">
      <thead>
        <tr>
          <th></th>
          <th>Lunes</th>
          <th>Martes</th>
          <th>Miércoles</th>
          <th>Jueves</th>
          <th>Viernes</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="session in sessions" :key="session.id">
          <th>
            {{ session.name }} -
            {{ formatTime(session.startHours, session.startMinutes) }} -
            {{ formatTime(session.endHours, session.endMinutes) }}
          </th>
          <SessionCell
            v-for="day in days"
            :key="day.toISOString()"
            :day="day"
            :session="session"
            :classrooms="classrooms"
            :replacements="replacements"
          />
        </tr>
        <tr>
          <th
            style="vertical-align: middle"
            class="has-text-centered has-background-light"
          >
            Observaciones
          </th>
          <td
            v-for="comment in commentsList"
            :key="comment.id"
            class="has-background-light"
          >
            <textarea
              v-model="comment.comments"
              readonly
              rows="7"
              class="has-background-light"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { urlBase } from "../../api/constants";
import { useComments } from "../../hooks/useComments";
import { useWeekNavigation } from "../../hooks/useWeekNavigation";
import SessionCell from "./PublicSessionCell.vue";
import { getClassrooms } from "../../api/classrooms";
import { getSessions } from "../../api/sessions";
import { getReplacements } from "../../api/replacements";
import { useWeek } from "../../hooks/useWeek";
import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useDateFormat } from "../../hooks/useDateFormat";

export default {
  components: {
    SessionCell,
  },
  async setup() {
    const store = useStore();
    const token = computed(() => store.state.token);
    const { weekDays } = useWeek();
    const days = ref(weekDays(new Date(), 5));
    const classrooms = ref(await getClassrooms(token.value, 1));
    const sessions = ref(await getSessions(token.value, 1));
    const replacements = ref(await getReplacements(token.value));
    const counter = ref(0);
    const { formatTime, dateFormat } = useDateFormat();
    const { getCommentsList } = await useComments(days.value, token.value);
    const commentsList = ref(await getCommentsList(days.value));

    const reloadReplacements = async () => {
      replacements.value = await getReplacements(
        token.value,
        days.value[0],
        days.value[4]
      );
    };

    const { getPreviousMonday, getNextMonday } = useWeekNavigation();

    const changeWeek = async (date) => {
      days.value = weekDays(new Date(date), 5);
      await reloadReplacements();
      commentsList.value = await getCommentsList(days.value);
    };

    setInterval(async () => {
      classrooms.value = await getClassrooms(token.value, 1);
      sessions.value = await getSessions(token.value, 1);
      replacements.value = await getReplacements(token.value);
      commentsList.value = await getCommentsList(days.value);
      counter.value++;
    }, 600000);

    const logoUrl = ref(
      `${urlBase}/api/organizations/logo?token=${token.value}`
    );
    return {
      classrooms,
      sessions,
      replacements,
      counter,
      days,
      formatTime,
      dateFormat,
      commentsList,
      getPreviousMonday,
      getNextMonday,
      changeWeek,
      logoUrl,
    };
  },
};
</script>

<style scoped>
th,
td {
  width: 12.5%;
}
pre {
  padding: 0 !important;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif !important;
}
textarea {
  border: 0 !important;
}
</style>
