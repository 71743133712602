<template>
  <div class="has-text-centered pb-3">
    <button
      type="button"
      class="button is-info is-small"
      @click="openForm(null)"
    >
      Agregar aula
    </button>
  </div>
  <table class="table is-fullwidth">
    <thead>
      <th :colspan="2">Aula</th>
    </thead>
    <tbody>
      <tr v-for="item in items" :key="item.id">
        <td>{{ item.name }}</td>
        <td class="has-text-right">
          <button
            type="button"
            class="button is-primary is-small"
            @click="openForm(item)"
          >
            Editar
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <ClassroomForm
    v-if="modalOpen"
    :classroom="selected"
    @close="updateListAndClose"
  />
</template>

<script>
import { ref } from "@vue/reactivity";
import { getClassrooms } from "../../api/classrooms";
import { useStore } from "vuex";
import { computed, watch } from "@vue/runtime-core";
import ClassroomForm from "./ClassroomForm.vue";
import { toRefs } from "vue";

export default {
  components: { ClassroomForm },
  props: {
    status: Number,
  },
  async setup(props) {
    const store = useStore();
    const token = computed(() => store.state.token);
    const { status } = toRefs(props);
    const items = ref(await getClassrooms(token.value, status.value));
    const selected = ref(null);
    const modalOpen = ref(false);
    const updateList = async () => {
      items.value = await getClassrooms(token.value, status.value);
    };
    const updateListAndClose = async () => {
      await updateList();
      modalOpen.value = false;
    };
    watch(status, updateList);

    const openForm = (classroom) => {
      selected.value = classroom;
      modalOpen.value = true;
    };

    return {
      items,
      modalOpen,
      openForm,
      selected,
      updateListAndClose,
    };
  },
};
</script>
