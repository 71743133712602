<template>
  <div class="select">
    <select :value="localClassroom" @input="change">
      <option value="">Selecciona aula</option>
      <option v-for="option in classrooms" :key="option.id" :value="option.id">
        {{ option["name"] }}
      </option>
    </select>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";
import { getClassrooms } from "../../api/classrooms";

export default {
  props: {
    classroom: Object,
  },
  emits: ["input"],
  async setup(props, context) {
    const store = useStore();
    const token = computed(() => store.state.token);
    const classrooms = ref(await getClassrooms(token.value, 1));
    const localClassroom = ref(props.classroom ? props.classroom.id : null);

    const change = (event) => {
      event.preventDefault();
      event.stopPropagation();
      const selected = classrooms.value.find(
        (e) => e.id === event.target.value
      );
      context.emit("input", selected);
      localClassroom.value = event.target.value;
    };

    return {
      classrooms,
      localClassroom,
      change,
    };
  },
};
</script>

<style scoped>
.select,
select,
option {
  width: 100%;
}
</style>
