import axios from "axios";
import { urlBase } from "./constants";
import { httpClient } from "./httpClient";

const login = async (username, password) => {
  try {
    const response = await axios.post(`${urlBase}/api/login_check`, {
      username,
      password,
    });
    return { code: response.status, body: response.data };
  } catch (e) {
    if (e.response) return { code: e.response.status, body: e.response.data };
    return { code: 500, body: {} };
  }
};

const getPublicToken = async (token) => {
  const client = httpClient(token);
  const url = `${urlBase}/api/public_token`;
  const { body } = await client.sendPost(url);
  return body;
};

const requestResetPassword = async (email) => {
  const url = `${urlBase}/api/reset_password_request`;
  const response = await axios.post(url, { email });
  return { code: response.status, body: response.data };
};

const resetPassword = async (password, token) => {
  const url = `${urlBase}/api/reset_password`;
  const response = await axios.put(url, { password, token });
  return { code: response.status, body: response.data };
};

const getUser = async (token) => {
  const client = httpClient(token);
  const url = `${urlBase}/api/me`;
  const { body } = await client.sendGet(url);
  return body;
};

const updateUser = async (token, data) => {
  const client = httpClient(token);
  const url = `${urlBase}/api/me`;
  const { body } = await client.sendPut(url, data);
  return body;
};

export {
  login,
  getPublicToken,
  requestResetPassword,
  resetPassword,
  getUser,
  updateUser,
};
