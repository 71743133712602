import { urlBase } from "./constants";
import { httpClient } from "./httpClient";

const getClassrooms = async (token, status = null) => {
  const client = httpClient(token);
  let url = `${urlBase}/api/classrooms`;
  if (status) {
    url += `?status=${status}`;
  }
  const { body } = await client.sendGet(url);
  return body;
};

const createClassroom = async (token, data) => {
  const client = httpClient(token);
  const url = `${urlBase}/api/classrooms`;
  const { body } = await client.sendPost(url, data);
  return body;
};

const updateClassroom = async (token, data) => {
  const client = httpClient(token);
  const url = `${urlBase}/api/classrooms/${data.id}`;
  const { body } = await client.sendPut(url, data);
  return body;
};

const saveClassroom = async (token, data) => {
  if (data.id) {
    return updateClassroom(token, data);
  }
  return createClassroom(token, data);
};

export { getClassrooms, saveClassroom };
