<template>
  <div>
    <h3 class="title is-5 mb-1 mt-4">Notificaciones</h3>
    <table class="table is-fullwidth is-narrow">
      <thead>
        <tr>
          <th>Fecha</th>
          <th>Tipo</th>
          <th>E-Mail</th>
          <th>Leído</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="notification in notifications" :key="notification.id">
          <td>{{ dateFormat(notification.date) }}</td>
          <td>
            {{ notification.type === "assign" ? "Asignación" : "Calación" }}
          </td>
          <td>{{ notification.recipient }}</td>
          <td>
            <span class="icon is-small mr-2">
              <i
                class="fas fa-times-circle has-text-danger"
                v-if="!notification.read"
              ></i>
              <i class="fas fa-check-circle has-text-success" v-else></i>
            </span>

            <span>{{
              notification.read ? dateFormat(notification.read) : ""
            }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { ref } from "vue";
import { useStore } from "vuex";
import { getReplacementNotifications } from "../../api/replacements";
import { useDateFormat } from "../../hooks/useDateFormat";

export default {
  name: "ReplacementNotifications",
  props: {
    replacementId: String,
  },
  async setup(props) {
    const store = useStore();
    const token = computed(() => store.state.token);

    const notifications = ref(
      await getReplacementNotifications(token.value, props.replacementId)
    );
    const dateFormat = (strDate) => {
      const { dateFormat } = useDateFormat();
      const parts = strDate.split("T")[1].split("+");
      return (
        dateFormat(new Date(strDate)).substring(0, 5) +
        " - " +
        parts[0].substring(0, 5)
      );
    };

    return {
      notifications,
      dateFormat,
    };
  },
};
</script>

<style scoped>
th,
td {
  font-size: 0.8rem;
}
</style>
