import { urlBase } from "./constants";
import { httpClient } from "./httpClient";

const getEmployees = async (token, status) => {
  const client = httpClient(token);
  let url = `${urlBase}/api/employees`;
  if (status) {
    url += `?status=${status}`;
  }
  const { body } = await client.sendGet(url);
  return body;
};

const getFreeEmployees = async (token, sessionId, date) => {
  if (!sessionId || !date) {
    return getEmployees(token, 1);
  }
  const client = httpClient(token);
  let url = `${urlBase}/api/free_employees?session=${sessionId}&date=${date.toISOString()}`;
  const { body } = await client.sendGet(url);
  return body;
};

const createEmployee = async (token, data) => {
  const client = httpClient(token);
  const url = `${urlBase}/api/employees`;
  const { body } = await client.sendPost(url, data);
  return body;
};

const updateEmployee = async (token, data) => {
  const client = httpClient(token);
  const url = `${urlBase}/api/employees/${data.id}`;
  const { body } = await client.sendPut(url, data);
  return body;
};

const saveEmployee = async (token, data) => {
  if (data.id) {
    return updateEmployee(token, data);
  }
  return createEmployee(token, data);
};

export { getEmployees, getFreeEmployees, saveEmployee };
