<template>
  <h1 class="title pt-4 pl-3">
    <span class="icon mr-2">
      <i class="fas fa-calendar has-text-grey-light"></i>
    </span>
    Planning
  </h1>
  <div class="p-3 box">
    <Suspense>
      <template #default>
        <Weekview />
      </template>
      <template #fallback>
        <div>Cargando...</div>
      </template>
    </Suspense>
    <Suspense>
      <template #default>
        <PublicLink />
      </template>
    </Suspense>
  </div>
</template>

<script>
import Weekview from "../components/Weekview/Weekview.vue";
import PublicLink from "../components/PublicLink/PublicLink.vue";
export default {
  components: {
    Weekview,
    PublicLink,
  },
};
</script>
