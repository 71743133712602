<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="card">
        <form @submit="submit" class="box">
          <div class="field">
            <div class="control">
              <div class="label">Nopmbre del aula</div>
              <input
                type="text"
                v-model="localClassroom.name"
                placeholder="Nombre del aula"
                class="input"
              />
            </div>
          </div>
          <div class="field">
            <div class="control mt-5 mb-5 has-text-centered">
              <label class="radio">
                <input
                  type="radio"
                  v-model="localClassroom.status"
                  :value="1"
                />
                Activa
              </label>
              <label class="radio">
                <input
                  type="radio"
                  v-model="localClassroom.status"
                  :value="2"
                />
                Anulada
              </label>
            </div>
          </div>
          <FormButtons
            :submitAction="submit"
            :cancelAction="cancel"
            :loading="loading"
          />
        </form>
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="$emit('close')"
    ></button>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { ref } from "vue";
import FormButtons from "../FormButtons.vue";
import { saveClassroom } from "../../api/classrooms";
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";

export default {
  components: {
    FormButtons,
  },
  props: {
    classroom: Object,
  },
  emits: ["close"],
  setup(props, context) {
    const loading = ref(false);
    const store = useStore();
    const token = computed(() => store.state.token);
    const localClassroom = reactive(
      props.classroom ? props.classroom : { id: null, name: "", status: 1 }
    );

    const submit = async () => {
      loading.value = true;
      try {
        await saveClassroom(token.value, localClassroom);
        loading.value = false;
        context.emit("close");
      } catch (error) {
        loading.value = false;
        alert(error.data.error.message);
      }
    };
    const cancel = () => {
      context.emit("close");
    };

    return {
      loading,
      localClassroom,
      submit,
      cancel,
    };
  },
};
</script>
