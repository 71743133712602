<template>
  <div class="select">
    <select :value="localEmployee" @input="change">
      <option value="">Selecciona empleado</option>
      <option v-if="employee" :value="employee.id">
        {{ employee.firstName }} {{ employee.lastName }}
      </option>
      <option v-for="option in employees" :key="option.id" :value="option.id">
        {{ option["firstName"] }} {{ option["lastName"] }}
      </option>
    </select>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { getFreeEmployees } from "../../api/employees";
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";

export default {
  props: {
    employee: Object,
    session: String,
    date: Date,
  },
  emits: ["input"],
  async setup(props, context) {
    const store = useStore();
    const token = computed(() => store.state.token);
    const employees = ref(
      await getFreeEmployees(token.value, props.session, props.date)
    );
    const localEmployee = ref(props.employee ? props.employee.id : null);

    const change = (event) => {
      event.preventDefault();
      event.stopPropagation();
      const selected = employees.value.find((e) => e.id === event.target.value);
      context.emit("input", selected);
      localEmployee.value = event.target.value;
    };

    return {
      employees,
      localEmployee,
      change,
    };
  },
};
</script>

<style scoped>
.select,
select,
option {
  width: 100%;
}
</style>
