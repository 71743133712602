<template>
  <div class="columns is-mobile is-centered mt-6">
    <div class="column is-12-mobile is-6-tablet is-4-widescreen">
      <form @submit="send" class="box">
        <div class="has-text-centered">
          <img src="img/logo.png" class="logo" alt="Gripau" />
        </div>
        <h1 class="title has-text-centered">Gripau</h1>
        <h2 class="title has-text-centered is-5 mb-5">
          Restablecer contraseña
        </h2>
        <p v-if="!done" class="has-text-centered">
          Escribe la dirección de e-mail con la que te registraste y te
          enviaremos un e-mail con un enlace donde podrás restablecer tu
          contraseña
        </p>
        <p
          v-if="done"
          class="has-background-success-light has-text-centered my-5 p-3"
        >
          Se ha enviado un email a {{ email }}. Por favor, comprueba tu correo.
        </p>
        <div class="field mt-5" v-if="!done">
          <div class="control">
            <div class="label">E-Mail</div>
            <input type="email" v-model="email" class="input" />
          </div>
        </div>
        <div class="field" v-if="!done">
          <div class="control has-text-centered">
            <button
              class="button is-primary"
              :class="{ 'is-loading': loading }"
              :disabled="loading"
            >
              Enviar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { requestResetPassword } from "../api/users";

export default {
  setup() {
    const email = ref("");
    const loading = ref(false);
    const done = ref(false);

    const send = async (e) => {
      e.preventDefault();
      if (email.value === "") {
        alert("Especifica un email");
        return;
      }

      try {
        loading.value = true;
        await requestResetPassword(email.value);
        done.value = true;
        loading.value = false;
      } catch (e) {
        loading.value = false;
        alert("E-Mail no encontrado.");
      }
    };

    return { email, loading, done, send };
  },
};
</script>
<style scoped>
img.logo {
  width: 150px;
  height: 150px;
}
</style>
