<template>
  <div class="modal" :class="{ 'is-active': modalOpen }" v-if="replacement">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="card">
        <form @submit="submit" class="box" v-if="replacementForm.date">
          <div class="field">
            <div class="control">
              <input
                type="text"
                v-model="formatedDate"
                placeholder="Fecha"
                readonly
                class="input"
              />
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input
                type="text"
                v-model="replacementForm.session.name"
                readonly
                placeholder="Sesión"
                class="input"
              />
            </div>
          </div>
          <div class="field">
            <div class="control" style="height: 40px">
              <Suspense>
                <template #default>
                  <!-- todo tricky hack, changeing id to force rerendering -->
                  <SelectClassroom
                    :classroom="replacementForm.classroom"
                    @input="changeClassroomm"
                    :key="'selectClassroom' + Math.random()"
                  />
                </template>
              </Suspense>
            </div>
          </div>
          <div class="field">
            <div class="control" style="height: 40px">
              <Suspense>
                <template #default>
                  <!-- todo tricky hack, changeing id to force rerendering -->
                  <SelectEmployee
                    :employee="replacementForm.employee"
                    :session="replacementForm.session.id"
                    :date="replacementForm.date"
                    @input="changeEmployee"
                    :key="'selectEmployee' + Math.random()"
                  />
                </template>
              </Suspense>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <textarea
                v-model="replacementForm.comments"
                rows="3"
                class="textarea"
              />
            </div>
          </div>
          <FormButtons
            :deleteAction="replacementForm.id ? deleteReplacement : null"
            :submitAction="submit"
            :loading="loading"
            :loadingDelete="loadingDelete"
            :cancelAction="
              () => {
                $emit('close');
              }
            "
          />
          <Suspense>
            <template #default>
              <ReplacementNotifications
                v-if="replacementForm.id"
                :replacement-id="replacementForm.id"
                :key="'selectEmployee' + Math.random()"
              />
            </template>
          </Suspense>
        </form>
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="$emit('close')"
    ></button>
  </div>
</template>

<script>
import { ref } from "vue";
import ReplacementNotifications from "./ReplacementNotifications";
import SelectClassroom from "./SelectClassroom.vue";
import FormButtons from "../FormButtons.vue";
import SelectEmployee from "./SelectEmployee.vue";
import { reactive } from "@vue/reactivity";
import { useDateFormat } from "../../hooks/useDateFormat";
import { computed } from "@vue/runtime-core";
import {
  saveReplacement,
  deleteReplacement as deleteReplacementApi,
} from "../../api/replacements";
import { useStore } from "vuex";

export default {
  components: {
    ReplacementNotifications,
    SelectClassroom,
    SelectEmployee,
    FormButtons,
  },
  props: {
    modalOpen: Boolean,
    replacement: Object,
  },
  emits: ["close"],
  setup(props, context) {
    const loading = ref(false);
    const loadingDelete = ref(false);
    const store = useStore();
    const token = computed(() => store.state.token);
    const { dateFormat } = useDateFormat();
    const replacementForm = reactive(props.replacement);
    const formatedDate = computed(() => dateFormat(props.replacement.date));
    const changeEmployee = (data) => (replacementForm.employee = data);
    const changeClassroomm = (data) => (replacementForm.classroom = data);
    const submit = async () => {
      if (!replacementForm.classroom) {
        alert("Debes seleccionar un aula");
        return;
      }
      loading.value = true;
      const data = {
        ...replacementForm,
        session: replacementForm.session.id,
        classroom: replacementForm.classroom.id,
        employee: replacementForm.employee?.id ?? null,
      };
      try {
        await saveReplacement(token.value, data);
        loading.value = false;
        context.emit("close");
      } catch (error) {
        loading.value = false;
        alert(error.data.error.message);
      }
    };

    const deleteReplacement = async () => {
      if (!confirm("¿Eliminar sustitución?")) {
        return;
      }
      loadingDelete.value = true;
      try {
        await deleteReplacementApi(token.value, replacementForm.id);
        loadingDelete.value = false;
        context.emit("close");
      } catch (error) {
        loadingDelete.value = false;
        alert(error.data.error.message);
      }
    };

    return {
      loading,
      loadingDelete,
      replacementForm,
      submit,
      dateFormat,
      formatedDate,
      changeEmployee,
      changeClassroomm,
      deleteReplacement,
    };
  },
};
</script>
