export const useWeekNavigation = () => {
  const getPreviousMonday = (date) => {
    date.setDate(date.getDate() - 7);
    return date;
  };

  const getNextMonday = (date) => {
    date.setDate(date.getDate() + 7);
    return date;
  };

  return {
    getPreviousMonday,
    getNextMonday,
  };
};
