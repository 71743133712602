import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";

import "./styles/style.scss";
import "../node_modules/@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css";

createApp(App).use(store).use(router).mount("#app");
